import * as api from '@commonenergy/js-lib-client/src/api/ce';
import firebase from '../firebase';

import { fetchJson } from '@commonenergy/js-lib-client/src/lib/fetch';

/**
 * @param  {function} fn api request function
 * @return {function} with idToken as last arg
 */
function withFirebaseAuthToken (fn) {
  return (...args) => firebase.auth.currentUser.getIdToken()
    .then(idToken => fn(...args, idToken));
}

// firebase user auth endpoints
// re-export with firebase auth token
export const claimContact = withFirebaseAuthToken(api.claimContact);
export const claimLead = withFirebaseAuthToken(api.claimLead);

export const createContactPaymentMethodACH = withFirebaseAuthToken(api.createContactPaymentMethodACH);
export const createContactPaymentMethodCredit = withFirebaseAuthToken(api.createContactPaymentMethodCredit);
export const createContactPaymentMethodPlaid = withFirebaseAuthToken(api.createContactPaymentMethodPlaid);
export const createLeadPaymentMethodACH = withFirebaseAuthToken(api.createLeadPaymentMethodACH);
export const createLeadPaymentMethodCredit = withFirebaseAuthToken(api.createLeadPaymentMethodCredit);
export const createLeadPaymentMethodPlaid = withFirebaseAuthToken(api.createLeadPaymentMethodPlaid);

export const createUser = withFirebaseAuthToken(api.createUser);
export const fetchAccountMeterDocuments = withFirebaseAuthToken(api.fetchAccountMeterDocuments);
export const fetchBillings = withFirebaseAuthToken(api.fetchBillings);
export const fetchContactDocuments = withFirebaseAuthToken(api.fetchContactDocuments);
export const fetchLeadUtilityAccount = withFirebaseAuthToken(api.fetchLeadUtilityAccount);
export const fetchInvoice = withFirebaseAuthToken(api.fetchInvoice);
export const fetchNearbyUsers = withFirebaseAuthToken(api.fetchNearbyUsers);
export const fetchPaymentMethodACH = withFirebaseAuthToken(api.fetchPaymentMethodACH);
export const fetchPaymentMethodCredit = withFirebaseAuthToken(api.fetchPaymentMethodCredit);
export const fetchPlaidLinkToken = withFirebaseAuthToken(api.fetchPlaidLinkToken);
export const fetchProjectInfo = withFirebaseAuthToken(api.fetchProjectInfo);
export const fetchReferrals = withFirebaseAuthToken(api.fetchReferrals);
export const fetchReferralDetails = withFirebaseAuthToken(api.fetchReferralDetails);
export const fetchUtilityAccounts = withFirebaseAuthToken(api.fetchUtilityAccounts);
export const fetchUserData = withFirebaseAuthToken(api.fetchUserData);
export const sendReferral = withFirebaseAuthToken(api.sendReferral);
export const updateLeadUtilityAccount = withFirebaseAuthToken(api.updateLeadUtilityAccount);
export const updateUser = withFirebaseAuthToken(api.updateUser);
export const updateUtilityAccount = withFirebaseAuthToken(api.updateUtilityAccount);
export const validateLeadForComplete = withFirebaseAuthToken(api.validateLeadForComplete);
export const validateUtilityCredentials = withFirebaseAuthToken(api.validateUtilityCredentials);

// anonymous and misc auth endpoints
export { createLead, fetchAgreement, fetchContact, fetchLead, fetchZip, logPlaidError, validateLeadForCreate } from '@commonenergy/js-lib-client/src/api/ce';
