import exists from './exists';
import findRight from './findRight';
import uniq from './uniq';

const emptyObj = {};

/**
 * deepMerge - non-mutating deep object merging
 * - resolves conflicting types with last found (and NOT NULL OR UNDEFINED)
 * - resolves conflicting values with last found
 * - no comparison of array values for deduplication aside from object identity
 * @param  {object[]} sources
 * @return {object}
 */
export default function deepMerge (...sources) {
  return mergeObjects([ {}, ...sources ]);
}

/**
 * filter values in preparation for merging
 * @param  {array} values
 * @return {array}
 */
function filterValues (values) {
  // determine type based on the last value that exists
  const type = getType(findRight(values, exists));

  // filter out any values that aren't of the same type (including undefined)
  return values.filter(v => getType(v) === type);
}

/**
 * get merge function for a given value based on its type
 * @param  {any} value
 * @return {function}
 */
function getMerger (value) {
  switch (getType(value)) {
  case 'array':
    return mergeArrays;
  case 'object':
    return mergeObjects;
  default:
    return mergePrimitives;
  }
}

/**
 * getType
 * @param  {any} x
 * @return {string} results of typeof or 'array' or 'null'
 */
function getType (x) {
  let type = typeof x;

  if (Array.isArray(x)) return 'array';
  if (type === 'object' && !x) return 'null';

  return type;
}

/**
 * merge object by applying the last value found for each key existing in any object
 * @param  {object[]} objs
 * @return {object}
 */
function mergeObjects (objs) {
  // get a list of keys existing in any object
  const keys = objs.reduce((keys, obj) => {
    keys.push(...Object.keys(obj));
    return keys;
  }, []);

  // dedupe key list and recursively build a merged object
  return uniq(keys).reduce((merged, key) => {
    const mergerFn = getMerger(findRight(objs, o => key in o)[key]);
    const values = filterValues(objs.map(obj => (obj || emptyObj)[key]));

    merged[key] = mergerFn(values);

    return merged;
  }, {});
}

/**
 * concats and dedupes, object identity comparison so only primitives will dedupe
 * @param  {array[]} arrays
 * @return {array}
 */
function mergeArrays (arrays) {
  return uniq(
    arrays.reduce((agg, arr) => {
      agg.push(...arr);
      return agg;
    }, [])
  );
}

/**
 * naively takes last value, perhaps it should take last value that's not null?
 * @param  {any[]} values
 * @return {any}
 */
function mergePrimitives (values) {
  return values[values.length - 1];
}
