import Cookie from 'js-cookie';
import fromEntries from '../../util/fromEntries.js';

const SPLITS = [
  'canary',
];

/**  @return {object} split: variation */
export function getSplits () {
  return fromEntries(SPLITS
    .map(split => [ split, Cookie.get(`x-split-key-${split}`) ])
    .filter(([ , val ]) => !!val)
  );
}
