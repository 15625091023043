// bind all methods except whose that match this regex (react lifecycle methods)
const REACT_REGEX = /^(component.*|render)$/;

/**
 * bind the `this` keyword in event handling class methods.
 * This is a side effects function, mutates instance parameter
 * @function bindMethodsTo
 * @param  {object} instance - object whose methods to look over and bind to itself
 */
export default function bindMethods (instance) {
  // look over all properties and methods of this object
  getPropertyNames(instance)
    // filter to only methods (eliminate properties)
    .filter(methodName => typeof instance[methodName] === 'function')
    // filter to only look at methods that don't match of regex
    .filter(methodName => !instance.isReactComponent || !methodName.match(REACT_REGEX))
    // bind these methods to the class instance
    .forEach(methodName => {
      instance[methodName] = instance[methodName].bind(instance);
    });
}

/**
 * @param  {object} instance
 * @return {string[]} property names of this object and ancestors
 */
function getPropertyNames (instance) {
  const propertyNames = [];
  let proto = instance;

  // starting with this instance and crawling our way up the prototype chain...
  // add all property names to an array until we hit the React.Component class
  while (proto && !Object.prototype.hasOwnProperty.call(proto, 'isReactComponent')) {
    propertyNames.push(...Object.getOwnPropertyNames(proto));
    proto = Object.getPrototypeOf(proto);
  }

  return propertyNames;
}
