import { getSplits } from '../split';
import { getChannel } from '../channel';

const DEBUG = false;

function getChannels () {
  // when we release channel subdomains
  // return { channel: getChannel() };
  return {};
}

export function alias (id) {
  if (DEBUG) console.info('alias', id);
  return global.analytics.alias(id);
}

export function identify (id, attr = {}) {
  if (DEBUG) console.info('identify', id, { ...getChannels(), ...getSplits(), ...attr });
  return global.analytics.identify(id, { ...getChannels(), ...getSplits(), ...attr });
}

export function page (page, attr = {}) {
  if (DEBUG) console.info('page', page, { ...getChannels(), ...getSplits(), ...attr });
  return global.analytics.page(page, { ...getChannels(), ...getSplits(), ...attr });
}

export function track (event, attr = {}) {
  if (DEBUG) console.info('track', event, { ...getChannels(), ...getSplits(), ...attr });
  return global.analytics.track(event, { ...getChannels(), ...getSplits(), ...attr });
}
