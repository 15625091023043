import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import omit from '../../util/omit';
import { getSplits } from '../../lib/split';

const omitUserKeys = omit([ 'accounts', 'notifications' ]);

export function initClient (): void {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV || process.env.NEXT_PUBLIC_APP_ENV,
    ignoreErrors: [
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
      'UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id',
    ],
    integrations: [
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: [ 'error' ],
      }),
    ],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: Number(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE),
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });

  Sentry.setContext('splits', getSplits());
}

export function initServer (): void {
  Sentry.init({
    dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_APP_ENV,
    integrations: [
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: [ 'error', 'warn' ],
      }),
    ],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: Number(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE),
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });

  Sentry.setContext('splits', getSplits());
}

export function setUser (user): void {
  user
    ? Sentry.setUser(omitUserKeys(user))
    : Sentry.configureScope(scope => scope.setUser(null));
}
