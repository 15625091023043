import _store from 'store2';

const store = _store.namespace('ce');

export function clear () {
  return store.clear();
}

/**
 * @param  {string} key
 * @param  {any} [defaultValue]
 * @return {any}
 */
export function get (key, defaultValue) {
  return has(key) ? store.get(key) : defaultValue;
}

/**
 * @param  {string} key
 * @return {boolean}
 */
export function has (key) {
  return store.has(key);
}

export function set (...args) {
  return store.set(...args);
}
