import firebase from 'firebase/app';
import 'firebase/auth';
import Router from "next/router";

import * as storage from '../lib/storage';
import { format as formatError } from '../lib/errors';

import { EMAIL_PATTERN_WHITELIST } from '../constants';

/** @return {object} */
function actionCode () {
  return {
    handleCodeInApp: true,
    url: `${process.browser ? window.location.origin : 'https://my.commonenergy.us'}/`,
  };
}

/**
 * @param  {string} email
 * @return {Promise}
 */
function emailIsPermitted (email) {
  // when not production env, email must pass at least one whitelisted pattern
  if (process.env.NEXT_PUBLIC_APP_ENV !== 'production' && !EMAIL_PATTERN_WHITELIST.some(regex => email.match(regex))) {
    // email not permitted in this env (to prevent sending emails to strangers)
    const message = `email address ${email} is not permitted in this enviroment`;

    console.warn(Error(message));

    return Promise.reject(formatError(message));
  }

  return Promise.resolve();
}

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_KEY,
  authDomain: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_BUCKET,
};

export default class Firebase {
  constructor() {
    this.app = !firebase.apps.length
      ? firebase.initializeApp(config)
      : firebase.app();

    this.auth = firebase.auth();
  }

  // AUTH API
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doEmailAuthProvider = (email, password, callback) => {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    this.auth.currentUser.linkWithCredential(credential).then(
      usercred => {
        const { user } = usercred;
        callback(user);
      },
      error => {
        console.error("Account linking error", error);
      }
    );
  };

  createUser = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  doSignOut = () => {
    global.analytics.reset();
    storage.clear();
    this.auth.signOut();
  };

  doGetCurrentUser = callback => {
    this.auth.onAuthStateChanged(user => {
      callback(user);
    });
  };

  doGetCurrentUserIdToken = callback =>
    this.auth.currentUser.getIdToken(true).then(
      idToken => callback(idToken),
      console.error
    );

  doUpdateUser = callback =>
    this.auth.onAuthStateChanged(
      user => {
        const { pathname } = Router;
        if (user) {
          user.getIdToken(true).then(
            idToken => callback(user, idToken),
            console.error
          );
        } else {
          if (pathname.includes("/dashboard")) {
            Router.push({
              pathname: "/"
            });
          }
        }
      },
      console.error
    );

  sendPasswordResetEmail = email => {
    return emailIsPermitted(email)
      .then(() => this.auth.sendPasswordResetEmail(email, actionCode()));
  };

  doConfirmPasswordReset = (code, newPassword, callback) =>
    this.auth.confirmPasswordReset(code, newPassword).then(callback());

  sendSignInEmail = email => {
    return emailIsPermitted(email)
      .then(() => this.auth.sendSignInLinkToEmail(email, actionCode()));
  };


  doCheckIsSignInWithEmailLink = url => this.auth.isSignInWithEmailLink(url);

  doSignInWithEmailLink = (email, completeUrl) =>
    this.auth.signInWithEmailLink(email, completeUrl);
}
