import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../constants.js';

import type { UserReferrals } from '@commonenergy/js-lib-client/src/models/User';

export default createSlice({
  name: 'referrals',
  initialState: initialState.referrals,
  reducers: {
    addLead (state: UserReferrals) {
      return {
        ...state,
        leads: {
          ...state.leads,
          allTime: state.leads.allTime + 1,
          thisMonth: state.leads.thisMonth + 1,
          thisWeek: state.leads.thisWeek + 1,
          today: state.leads.today + 1,
        },
      };
    },
    set (state: UserReferrals, { payload }) {
      return payload;
    },
  },
});
