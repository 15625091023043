import { createSelector } from '@reduxjs/toolkit';

import omitEmpty from '../../util/omitEmpty.js';

export const getAccountIndex = state => state.accountIndex;
export const getFirebaseUserId = state => state.firebaseUserId;
export const getOnboarding = ({ onboarding }) => omitEmpty(onboarding);
export const getReferralDetails = state => state.referralDetails;
export const getReferrals = state => state.referrals;
export const getStateToPersist = state => state.onboarding;
export const getUser = state => state.user;
export const getUserAccount = createSelector(
  getAccountIndex,
  getUser,
  (index, user) => {
    if (!Array.isArray(user.accounts)) return {};

    return user.accounts[index] || user.accounts[0] || {};
  },
);
