const CHANNEL_WHITELIST = [
  'affiliate',
  'customer', // default
];
const CHANNEL_REGEX = new RegExp(`^([^.]+)\.(${CHANNEL_WHITELIST.join('|')})\.((staging)\.)?my\.commonenergy\.us`); /* eslint-disable-line no-useless-escape */

/**
 * @param  {string} hostname
 * @return {string[]} undefined when no match
 */
function parseArgsFromHostname (hostname) {
  const [ , value, channel ] = hostname.match(CHANNEL_REGEX) || [];

  if (channel || value) {
    return [ channel, value ];
  }
}

/**
 * @param  {string} [hostname=location.hostname]
 * @return {string}
 */
export function getChannel (hostname = location.hostname) {
  const [ channel ] = parseArgsFromHostname(hostname) || [];

  return channel || 'customer';
}

/**
 * @param  {string} [hostname=location.hostname]
 * @return {string[]}
 */
export function getChannelArgs (hostname = location.hostname) {
  return parseArgsFromHostname(hostname) || [];
}
