import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../constants.js';

export default createSlice({
  name: 'accountIndex',
  initialState: initialState.accountIndex,
  reducers: {
    set (state, { payload }) {
      return payload;
    },
  },
});
