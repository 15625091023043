/**
 * findRight - behaves like Array.prototype.find except that we iterate in reverse
 * @param  {array} arr
 * @param  {function} predicate - receiving iteration value, index and array
 * @return {any|undefined} the last item in array passing predicate function or undefined if no match
 */
export default function findRight (arr, predicate) {
  let x;

  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i], i, arr)) return arr[i];
  }

  return x;
}
