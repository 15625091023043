export const initialState = {
  accountIndex: 0,
  firebaseUserId: '',
  onboarding: {
    affiliate: null, // string
    billingMethod: null, // object | string
    email: null, // string deprecate
    impactAdvocate: null, // string
    offer: null, // string
    partner: null, // string
    referrer: null, // string
    referrerPage: null, // string
    salesRep: null, // string
    spend: 200,
    state: null, // string
    username: null, // deprecate object
    utility: null, // object | string
    utmCampaign: null, // string
    utmMedium: null, // string
    utmSource: null, // string
    zip: null, // string
  },
  referralDetails: {
    contacts: [],
    leads: [],
  },
  referrals: {
    contacts: {},
    leads: {},
  },
  user: {
    accounts: [],
    notifications: [],
  },
};

export const onboardingKeysToPreserveOnReset = [
  'affiliate',
  'impactAdvocate',
  'partner',
  'referrer',
];
