/* eslint no-fallthrough: 0 */
import qs from 'query-string';
import User from '@commonenergy/js-lib-client/src/models/User';
import Utility from '@commonenergy/js-lib-client/src/models/Utility';

import { initialState, onboardingKeysToPreserveOnReset } from '../../store/constants.js';
import { getOnboarding, getUser } from '../../store/selectors';

import exists from '../../util/exists.js';
import firebase from '../../firebase';
import fromEntries from '../../util/fromEntries.js';
import pick from '../../util/pick.js';

const ANONYMOUS_PATHS = [
  '/',
  '/_error',
  '/emailsignin',
  '/forgot-password',
  '/onboarding/claim-account',
  '/onboarding/searching', // TODO: can we destroy this page?
  '/onboarding/sorry',
  '/onboarding/sorryThanks',
  '/onboarding/stepAkamai',
  '/onboarding/step1',
  '/onboarding/step2',
  '/onboarding/step3',
  '/reset-password',
];
const QUERY_BLACKLIST = [ 'forgotPwdLink' ];
const START_PATH = '/onboarding/step1';

/**
 * @param  {string} currentStep - 'step1'
 * @param  {object} kwargs
 * @param  {object} router
 * @param  {object} store
 * SIDE-EFFECT: Next routing
 */
export function next (currentStep, kwargs, { router, store }) {
  const onboarding = getOnboarding(store.getState());
  const user = getUser(store.getState());
  const step = getNextStep(currentStep, kwargs, { onboarding, user });
  const route = { pathname: `/onboarding/${step}` };

  // append the query string if we're not logged in yet
  route.query = !User.exists(user)
    ? onboardingToQueryArgs(onboarding)
    : { next: true };

  // TODO: find a better place for this
  // typically one flag of the two User.milestones.(utilityOnlineOnly|utilityPaperOnly) will be set
  // but it's valid for neither to be set to indicate user choice. In such a case,
  // we need a mechanism to persist that state from stepUtilityBillSelect to step4 and across a reload
  if (step === 'step4' && !User.getBillingMethod(user)) {
    route.query.billingMethod = onboarding.billingMethod;
  }
  else if (step === 'step8' && onboarding.paymentMethod) {
    route.query.paymentMethod = onboarding.paymentMethod;
  }

  // console.info('next', route);

  // navigate
  router.push(route);
}

// SIDE-EFFECT: browser routing
export function reset ({ store }) {
  const onboarding = getOnboarding(store.getState());
  let stateToPreserveAsQueryString = qs.stringify(pick(onboardingKeysToPreserveOnReset, onboarding));

  if (stateToPreserveAsQueryString) stateToPreserveAsQueryString = '?' + stateToPreserveAsQueryString;

  firebase.doSignOut();
  window.location = START_PATH + stateToPreserveAsQueryString;
}

/**
 * replaces route-user
 * @param  {object} router - nextjs router
 * @param  {object} store  - redux store
 * SIDE-EFFECT: browser routing
 */
export function resume (router, store) {
  const isDashboard = !!router.pathname.match(/^\/?dashboard/);
  const onboarding = getOnboarding(store.getState());
  const requiresLogin = !ANONYMOUS_PATHS.includes(router.pathname);
  const user = getUser(store.getState());

  let routePromise = Promise.resolve();
  /**
   * @param  {string} pathname may also be an href, something similar to router.asPath
   * @param  {object|null} query - null will ignore existing query args
   * SIDE-EFFECT: sets enclosed routePromise
   */
  function route (pathname, query) {
    const route = query === null ? pathname : { pathname, query };

    routePromise = router.replace(route);
  }

  // shortcircuit, for QA convenience, should obsolete query.next and query.onboardingNotFinished
  if (router.query.resume === 'false') return routePromise;

  // anonymous users on anonymous paths
  if (!User.exists(user) && !requiresLogin) {
    // validate step has required onboarding state or redirect to step1
    if (!validateAnonymousStep(router.pathname, onboarding)) {
      route('/onboarding/step1', onboardingToQueryArgs(onboarding));
    }
  }
  // user not logged in and requested a dashboard page need to be redirected through login
  else if (!User.exists(user) && requiresLogin) route('/', { redirect: router.asPath.replace(/[?&]next=true/, '') }); // strip out ?next=true
  // users with a redirect request (just logged in) should be redirected to initially requested page
  else if (router.query.redirect) window.location.replace(router.query.redirect); // hard redirect to run resume again
  // generic returning users (logged in) resuming onboarding
  else if (!router.query.next && !router.query.onboardingNotFinished) {
    // resume onboarding
    if (!user.signupCompleted && !isDashboard) {
      route(`/onboarding/${getResumeStep(user, onboarding)}`, { onboardingNotFinished: true });
    }
    // onboarding already complete, go to dashboard
    else if (!isDashboard) route('/dashboard');
  }

  // routePromise.then(route => console.info('resuming', route));

  return routePromise;
}

/**
 * @param  {string} currentStep - 'step1'
 * @param  {string} geostatus  - 'Live'
 * @param  {object} onboarding
 * @param  {object} user
 * @return {string} 'step2'
 */
function getNextStep (currentStep, { geostatus }, { onboarding, user }) {
  const { billingMethod, offer, partner = '', state, utility } = onboarding;
  const hasAddress = user.milestones?.addressInfoCompleted;

  switch (currentStep) {
  case 'step1':
    return geostatus === 'Live' ? 'step2' : 'sorry';
  case 'sorry':
    return 'sorryThanks';
  case 'step2':
    return partner.toLowerCase() === 'akamai' ? 'stepAkamai' : 'step3';
  case 'step3':
    if (offer === 'lmi' && state === 'MD') return 'stepLMI';
    else if (offer === 'lmi' && state === 'NJ') return 'stepLMINJ';
    else if (state === 'OR') return 'step3.1';
    else if ([ 'nike', 'microsoft' ].includes(partner.toLowerCase())) return 'stepExtra';
  case 'stepLMI':
  case 'stepLMINJ':
  case 'step3.1':
  case 'stepExtra':
    return billingMethod ? 'step4' : 'stepUtilityBillSelect';
  case 'stepUtilityBillSelect':
    return 'step4';
  case 'step4':
    if (billingMethod === 'paper' && Utility.requiresAddress(utility) && !hasAddress) return 'step4.2';
  case 'step4.2':
    if (user.isAnonymous) return 'step5';
    else return 'step7';
  case 'step7':
    return 'step8';
  case 'step8':
    return 'validation';
  default:
    return 'step1';
  }
}

/**
 * @param  {User} user
 * @param  {object} onboarding
 * @return {string} step4 for example
 */
function getResumeStep (user, onboarding) {
  const { billingMethod } = onboarding;
  const { addressInfoCompleted, address, agreementChecked, bankInfoCompleted, utility, utilityInfoCompleted } = user?.milestones || {};
  const { geoStatus, isAnonymous } = user;
  const { state } = address;

  if (geoStatus === 'No CDG Program') return 'sorry';
  else if (state === 'OR' && !agreementChecked) return 'step3.1';
  else if (!utilityInfoCompleted && !billingMethod) return 'stepUtilityBillSelect';
  else if (!utilityInfoCompleted && billingMethod) return 'step4';
  else if (!addressInfoCompleted && Utility.requiresAddress(utility)) return 'step4.2';
  else if (isAnonymous) return 'step5';
  else if (!bankInfoCompleted) return 'step7';
  else return 'validation';
}

/**
 * @param  {object} onboarding
 * @return {object}
 */
function onboardingToQueryArgs (onboarding) {
  return fromEntries(Object.entries(onboarding)
    .filter(([ key, val ]) => (
      exists(val)
      && val !== initialState.onboarding[key]
      && !QUERY_BLACKLIST.includes(key)
    ))
  );
}

/**
 * @param  {string} pathname
 * @param  {string} [partner]
 * @param  {string} [state]
 * @param  {string} [utility]
 * @param  {string} [zip]
 * @return {boolean}
 */
function validateAnonymousStep (pathname, { partner, state, utility, zip }) {
  const step = pathname.split('/').pop();
  const testAkamai = () => partner && partner.toLowerCase() === 'akamai'; // parter present and valid
  const testState = () => state && state.match(/^[A-Z]{2}$/) !== null; // state present and valid
  const testUtility = () => utility && utility.length > 0; // utility present and not empty
  const testZip = () => zip && zip.match(/^\d{5}$/) !== null; // zip present and valid

  const validationMap = {
    sorry: [ testUtility, testZip ],
    stepAkamai: [ testAkamai, testUtility, testZip ],
    step2: [ testState, testUtility, testZip ],
    step3: [ testState, testUtility, testZip ],
  };
  const validations = validationMap[step] || [];

  // every validation test for this step must pass
  return validations.every(fn => fn());
}
