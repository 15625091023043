/**
 * Intended to used as a Promise rejection handler, passes a rejection reason (error)
 * to the supplied onRejection function and then throw the result of onRejection.
 * NOTE: wrap onRejection in util/tap for side effects only and rethrow original error
 *
 * @param  {function} onRejection
 * @return {function}
 */
export default function rethrow (onRejection) {
  return error => {
    throw onRejection(error);
  };
}
