import Cookie from 'js-cookie';
import qs from 'query-string';
import { getChannelArgs } from '../lib/channel';
import * as storage from '../lib/storage';
import { initialState } from './constants.js';

import curry from '../util/curry';
import deepMerge from '../util/deepMerge';
import pick from '../util/pick';
import pipe from '../util/pipe';

const ONBOARDING_NORMALIZATION_MAP = {
  advocate: 'referrer',
  offer (key, val) {
    if (val === '25') val = 'lmi'; // TODO: consider a switch if this grows
    return [ key, val ];
  },
  postalCode: 'zip',
  spend: (key, val) => [ key, Number(val) ], // Numberify our value
  utm_campaign: 'utmCampaign',
  utm_medium: 'utmMedium',
  utm_source: 'utmSource',
  zipcode: 'zip',
  '25': 'lmi',
};

const ONBOARDING_QUERY_KEY_WHITELIST = [
  'affiliate',
  'billingMethod',
  'impactAdvocate',
  'offer',
  'partner',
  'paymentMethod',
  'referrer',
  'referrerPage',
  'rep',
  'spend',
  'state',
  'utility',
  'utmCampaign',
  'utmMedium',
  'utmSource',
  'zip',
];

const ONBOARDING_LOCALSTORAGE_KEY_WHITELIST = [
  'forgotPwdLink', // TODO: replace with utilities endpoint response
];

const onboardingKeyNormalize = curry(normalizeFromMap)(ONBOARDING_NORMALIZATION_MAP); // obj => obj
export default function getPreloadedState () {
  if (!process.browser) return initialState;

  const state = deepMerge(
    initialState,
    getStateFromCookie(),
    getStateFromLocalStorage(),
    getStateFromHostname(),
    getStateFromQueryParams(),
  );

  // hack because partner pages do not allow to add parameters to cta links
  if (state.onboarding.partner === 'washingtongardens') state.onboarding.offer = 'lmi';

  return state;
}

function getStateFromCookie () {
  const normalizeFalsy = val => val || null;

  let referrerPage = Cookie.get('ce_aff_slug');
  referrerPage = referrerPage && referrerPage.match(/(impact|rep|team)/)
    ? referrerPage
    : document.referrer;

  return {
    onboarding: {
      affiliate: normalizeFalsy(Cookie.get('ce_aff')),
      impactAdvocate: normalizeFalsy(Cookie.get('ce_impact_advocate')),
      partner: normalizeFalsy(Cookie.get('ce_rep_referral') || Cookie.get('partner_referral')),
      referrer: normalizeFalsy(Cookie.get('customer_referral')),
      referrerPage: normalizeFalsy(referrerPage),
      utmCampaign: normalizeFalsy(Cookie.get('_ce_campaign')),
      utmMedium: normalizeFalsy(Cookie.get('_ce_medium')),
      utmSource: normalizeFalsy(Cookie.get('_ce_source')),
    },
  };
}

function getStateFromHostname () {
  const CHANNEL_ONBOARDING_MAP = {
    affiliate: 'affiliate',
  };
  const [ channel, value ] = getChannelArgs(location.hostname);
  const onboarding = CHANNEL_ONBOARDING_MAP[channel] && value
    ? { [CHANNEL_ONBOARDING_MAP[channel]]: value }
    : {};

  return { onboarding };
}

function getStateFromLocalStorage () {
  const persistentValue = storage.get('onboarding', initialState.onboarding);
  const pipeFn = pipe(onboardingKeyNormalize, pick(ONBOARDING_LOCALSTORAGE_KEY_WHITELIST));

  return {
    onboarding: pipeFn(persistentValue),
  };
}

function getStateFromQueryParams () {
  const parsedValue = qs.parse(location.search);
  const pipeFn = pipe(onboardingKeyNormalize, pick(ONBOARDING_QUERY_KEY_WHITELIST));

  return {
    onboarding: pipeFn(parsedValue),
  };
}

/**
 * @param  {object} map
 * @param  {object} obj
 * @return {object}
 */
function normalizeFromMap (map, obj) {
  return Object.entries(obj).reduce((_obj, [ key, val ]) => {
    /** @const {function|string|undefined} mapped */
    const mapped = map[key];
    let newKey, newVal;

    switch (typeof mapped) {
    case 'function':
      [ newKey, newVal ] = mapped(key, val);
      break;
    case 'string':
      newKey = mapped;
      newVal = val;
      break;
    default:
      newKey = key;
      newVal = val;
    }

    _obj[newKey] = newVal;

    return _obj;
  }, {});
}
