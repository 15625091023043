import React from 'react';
import { FadeLoader } from "react-spinners";

export default function Loader ({ fixedCenter = false }) {
  return (
    <div className="wrapper">
      <FadeLoader
        className="spinner"
        height={15}
        width={4}
        radius={1}
        color={"#FF69A0"}
        loading
      />
      <style jsx>{`
        .wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          position: ${fixedCenter ? 'fixed' : 'static'};
        }
      `}</style>
    </div>
  );
}
