import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../constants.js';

export default createSlice({
  name: 'referralDetails',
  initialState: initialState.referralDetails,
  reducers: {
    addLead (state, { payload }) {
      return {
        ...state,
        leads: state.leads.concat(payload),
      };
    },
    set (state, { payload }) {
      return payload;
    },
  },
});
