import { alert as modalAlert } from '@commonenergy/js-lib-client/src/lib/modal';

export function alert (body = 'An unexpected error has occurred. Please try again or contact support if the problem persists') {
  return modalAlert({ body });
}

/**
 * @param  {object|array|string} e
 * @return {object} { code, message, name }
 */
export function format (e) {
  switch (typeof e) {
  case 'string':
    return {
      message: e,
    };
  case 'object':
    // NOTE: for future use
    if (Array.isArray(e)) return {
      name: e[0],
      message: e[1],
    };
  }

  return {
    code: e?.code,
    message: e?.message || e?.msg,
    name: e?.name || e?.param,
  };
}
