/**
 * fromEntries - Object.fromEntries
 * @param  {Array}  [entries=[]]
 * @return {object}
 */
export default function fromEntries (entries = []) {
  if (typeof Object.fromEntries === 'function') return Object.fromEntries(entries);

  return entries.reduce((obj, [ key, val ]) => {
    obj[key] = val;

    return obj;
  }, {});
}
