import { configure } from '@commonenergy/js-lib-client';

try {
  configure({
    ceApiBaseUrl: process.env.NEXT_PUBLIC_API,
  });
}
catch (e) {
  console.error(e);
}
