import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../constants.js';

export default createSlice({
  name: 'onboarding',
  initialState: initialState.onboarding,
  reducers: {
    clear (state) {
      return initialState.onboarding;
    },
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },
});
