import User from '@commonenergy/js-lib-client/src/models/User';
import { alert } from '@commonenergy/js-lib-client/src/lib/modal';
import pubsub from '@curiouser/pubsub';
import memo from 'memoize-one';
import Link from 'next/link';
import React from 'react';

import * as api from '../../api/ce';

import { getFirebaseUserId, getOnboarding, getUser } from '../selectors';
import * as slices from '../slices';

import firebase from '../../firebase';
import rethrow from '../../util/rethrow';
import tap from '../../util/tap';
import { format as formatErrors } from '../../lib/errors';

export function claimAccount ({ agreementChecked, contactId, email, leadId }) {
  return async (dispatch, getState) => {
    const firebaseUserId = getFirebaseUserId(getState());
    const body = { agreementChecked, email, firebaseUserId };

    try {
      // claim contact or lead in SalesForce for the current firebase user
      if (contactId) await api.claimContact(contactId, body);
      else if (leadId) await api.claimLead(leadId, body);

      // refresh user in store
      return dispatch(syncUser(firebaseUserId));
    }
    catch (e) {
      return Promise.reject(e.errors.map(formatErrors));
    }
  };
}

export function createFirebaseUser (email, password) {
  return dispatch => {
    // create user in firebase
    return firebase.createUser(email, password)
      // with firebase user, create CE user
      .then(async userCredential => {
        const firebaseUser = userCredential.user;

        dispatch(slices.firebaseUserId.actions.set(firebaseUser.uid));

        return firebaseUser;
      }, rethrow(formatErrors));
  };
}

export function createUser (email, password, user) {
  return async (dispatch, getState) => {
    try {
      const firebaseUserId = getFirebaseUserId(getState());

      // create CE user in SalesForce
      await api.createUser({ ...user, firebaseUserId });

      // refresh user in store
      return dispatch(syncUser(firebaseUserId))
        .then(tap(user => pubsub.pub('user.created', user)));
    }
    catch (e) {
      throw formatErrors(e);
    }
  };
}

// TODO: is memoization a good idea here?
export const init = memo(function init () {
  return memo(dispatch => {
    // TODO: is memoization a good idea here?
    // only allow init to run once and return previous init promise
    return Promise.all([
      dispatch(initUser()),
    ]);
  });
});

export function initUser () {
  return async (dispatch, getState) => {
    // only listen for the firebaseUser initialization
    return new Promise(resolve => firebase.auth.onIdTokenChanged(resolve))
      .then(async firebaseUser => {
        // if we have a firebaseUser
        if (firebaseUser) {
          dispatch(slices.firebaseUserId.actions.set(firebaseUser.uid));

          // sync CE user if account exists
          return dispatch(syncUser(firebaseUser.uid))
            .then(tap(user => pubsub.pub('user.sessionRestored', user)));
        }
      })
      // always return user from store
      .then(() => getUser(getState()));
  };
}

export function signIn (email, password, leadFirebaseMerge) {
  if (!email || !password) throw Error('You must supply email and password to signIn');

  return dispatch => {
    return firebase.doSignInWithEmailAndPassword(email, password)
      .catch(rethrow(failure => {
        return failure.code === 'auth/wrong-password'
          ? {
            code: failure.code,
            message: (
              <>
                The password is invalid. Try again or{" "}
                <Link href="/forgot-password">click here</Link> to reset your password.
              </>
            )
          } : failure;
      }))
      .then(async ({ user }) => {
        // update store
        dispatch(slices.firebaseUserId.actions.set(user.uid));

        // update user
        if (leadFirebaseMerge) {
          await dispatch(updateUser({ email, firebaseUserId: user.uid, leadFirebaseMerge: true }));
        }

        // sync user on sign-in
        return dispatch(syncUser(user.uid));
      })
      .then(tap(user => pubsub.pub('user.loggedIn', user)));
  };
}

export function signInWithEmailLink (email, href) {
  return dispatch => {
    return firebase.doSignInWithEmailLink(email, href)
      .then(async ({ user }) => {
        // update store
        dispatch(slices.firebaseUserId.actions.set(user.uid));

        // update user on sign-in
        return dispatch(updateUser({ email, firebaseUserId: user.uid, leadFirebaseMerge: true }))
          // finally, sync our updated user
          .then(() => dispatch(syncUser(user.uid)));
      })
      .then(tap(() => global.analytics.track('User Signed In', {})));
  };
}

export function syncUser (userId) {
  return (dispatch, getState) => {
    if (!userId) userId = getFirebaseUserId(getState());
    const { email, isAnonymous } = firebase.auth.currentUser || {};

    return api.fetchUserData(userId)
      .catch(rethrow(e => {
        console.warn(e);
        console.error(new Error(`User with email "${email}" and userId "${userId}" has no matching records`));
        alert({ body: 'We could not find your account. Please try again or contact support if the problem persists' })
          .then(() => {
            firebase.doSignOut();
            window.location = '/';
          });

        return {
          message: 'Unexpected internal error. Please contact support@commonenergy.us',
        };
      }))
      .then(user => ({ ...user, isAnonymous }))
      .then(tap(user => dispatch(slices.user.actions.set(user))))
      .then(tap(user => {
        if (!getOnboarding(getState()).billingMethod) {
          dispatch(slices.onboarding.actions.update({
            billingMethod: User.getBillingMethod(user),
          }));
        }
      }));
  };
}

export function updateUser (user) {
  return (dispatch, getState) => {
    const { contactId, leadId } = getUser(getState());
    if (!user.leadId && leadId) user.leadId = leadId;
    if (!user.contactId && contactId) user.contactId = contactId;

    return api.updateUser(user);
  };
}
