import curry from './curry';

/**
 * curried replacement for lodash.omit (not the same signature though)
 * @param  {string|array} key - what to copy from source obj
 * @param  {object} src - source object to pick values from
 * @return {object}
 */
export default curry(function omit (keys, src) {
  if (!Array.isArray(keys)) keys = [keys];

  return Object.fromEntries(
    Object
      .entries(src)
      // filter out blacklisted keys
      .filter(([ key ]) => !keys.includes(key))
  );
});
