import { configureStore } from '@reduxjs/toolkit';
import { from } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged } from 'rxjs/operators';

import getPreloadedState from './getPreloadedState';
import reducers from './reducers';
import * as slices from './slices';

const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_APP_ENV !== 'production',
  preloadedState: getPreloadedState(),
  reducer: {
    ...reducers,
    accountIndex: slices.accountIndex.reducer,
    firebaseUserId: slices.firebaseUserId.reducer,
    onboarding: slices.onboarding.reducer,
    referralDetails: slices.referralDetails.reducer,
    referrals: slices.referrals.reducer,
    user: slices.user.reducer,
  },
});

export default store;
