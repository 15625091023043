export const BILLING_METHODS = [
  'paper',
  'online',
];

export const EMAIL_PATTERN_WHITELIST = [
  /@commonenergy.us$/,
  /@mailinator.com$/,
  /@ce.us$/,
];

export const SUPPORT_EMAIL_ADDRESS = 'support@commonenergy.us';
export const SUPPORT_PHONE_NUMBER = '+18448999763';
