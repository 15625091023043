import '../lib/js-lib-client.js';
import App from 'next/app';
import Head from 'next/head';
import pubsub from '@curiouser/pubsub';
import React from 'react';
import { Provider } from 'react-redux';

import store from '../store';
import { init as initStore } from '../store/actions';

import Loader from '../components/Loader';

import bindMethods from '../util/bindMethods.js';
import firebase from '../firebase';
import * as onboarding from '../lib/onboarding';
import settings from '../settings.json';
import * as sentry from '../lib/sentry';
import * as tracking from '../lib/tracking';

import '../index.scss';

if (process.browser && process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
  window.app = { firebase, store };
}

export default class MyApp extends App {
  state = {
    isLoading: !!process.browser,
  };

  constructor (...args) {
    super(...args);
    bindMethods(this);

    const { router } = this.props;

    if (process.browser) {
      store.dispatch(initStore())
        // route user to best guess of where they should go
        .then(() => new Promise(res => {
          const promise = onboarding.resume(router, store);
          setTimeout(() => {
            // nextjs in dev mode doesn't resolve this promise until the component is rendered it seems
            res(process.env.NODE_ENV === 'production' ? promise : Promise.resolve());
          }, 0);
        }))
        .then(() => this.setState({ isLoading: false }));

      pubsub.sub('user.sessionRestored', identifyUser);
      pubsub.sub('user.loggedIn', identifyUser);
      pubsub.sub('user.created', identifyUser);
      pubsub.sub('user.loggedIn', () => global.analytics.track('User Signed In', {}));
      pubsub.sub('user.loggedIn', this.resume); // redirect if requested
      pubsub.sub('user.created', () => global.analytics.track('Lead Created', {}));
    }
  }

  /**
   * @param  {string}   step - onboarding step 'step1'
   * @param  {object}   [kwargs={}] - kwargs are onboarding routing, but most everything should be in onboarding store state
   */
  next (path, kwargs = {}) {
    // allows pubsub subscriptions to run before navigation
    setTimeout(() => onboarding.next(path, kwargs, { router: this.props.router, store }), 0);
  }

  resume () {
    return onboarding.resume(this.props.router, store);
  }

  render () {
    const { Component, pageProps } = this.props;
    // show a loader graphic until our app has been initialized
    const jsx = this.state.isLoading
      ? <Loader fixedCenter />
      : <Component {...pageProps} next={this.next} resume={this.resume} />;

    return (
      <Provider store={store}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no user-scalable=no" />
          <title>{settings.meta.pageTitle}</title>
        </Head>
        {jsx}
      </Provider>
    );
  }
}

/**
 * identify user with Sentry, Segment
 * @param  {User} user
 * SIDE-EFFECTS
 */
function identifyUser (user) {
  sentry.setUser(user);
  tracking.alias(user.leadId);
  tracking.identify(user.leadId, {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  });
}
