import exists from './exists.js';
import fromEntries from './fromEntries.js';

/**
 * @param  {object}  obj
 * @param  {boolean} [nullIsEmpty=true] should we consider null values as empty?
 * @return {object}
 */
export default function omitEmpty (obj, nullIsEmpty = true) {
  const isEmpty = nullIsEmpty
    ? v => !exists(v)
    : v => v === undefined || v === null;

  return fromEntries(Object
    .entries(obj)
    .filter(([ , v ]) => !isEmpty(v))
  );
}
